<template>
  <a-select
    v-model:value="valueState"
    style="width: 300px"
    allow-clear
    not-found-content="Tidak ditemukan"
    option-filter-prop="label"
    option-label-prop="label"
    @search="onSearch"
    @blur="() => (findText = null)"
    @select="() => (findText = null)"
    v-bind="$attrs"
    :loading="loading"
    :show-arrow="true"
  >
    <a-select-option v-for="d in data" :key="d.id" :label="d.value">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="highlight(d.value)"></span>
      <!--eslint-enable-->
    </a-select-option>
  </a-select>
</template>

<script>
import { onMounted, ref } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { debounce } from 'lodash'

export default {
  props: {
    value: { type: [Array, Number, Object, String], default: null },
  },
  emits: ['update:value'],
  setup(props) {
    const data = ref([])
    const findText = ref(null)
    const loading = ref(false)

    const fetchData = (q = null) => {
      loading.value = true
      apiClient.get('/api/filter/loyalty-uom-name', { params: { q } })
        .then(response => {
          const sorted = response.data
          data.value = sorted.sort((a, b) =>
            a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
          )
        })
        .finally(() => {
          loading.value = false
        })
    }

    onMounted(() => {
      fetchData()
    })

    const onSearch = debounce(value => {
      loading.value = true
      findText.value = value
      fetchData(value)
    }, 300)

    const highlight = value => {
      return value.replace(new RegExp(findText.value, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }
    return {
      data,
      valueState: useVModel(props, 'value'),
      findText,
      highlight,
      onSearch,
      loading,
    }
  },
}
</script>

<style></style>
